
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from 'react';

function App() {

  const [value, setValue] = useState(null);
  const [doc, setDoc] = useState("0");
  const [rut, setRut] = useState(null);
  const [folio, setFolio] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [total, setTotal] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);


  function onChange(value) {
    //console.log("Captcha value:", value);
    setValue(value);
  }

  function send() {
    console.log({ value });
    if (value == null) {
      alert('Verifique que no es un robot');
      return;
    }
    console.log({ doc });
    if (doc === "0") {
      alert('Seleccione un documento');
      return;
    }
    console.log({ folio });
    if (folio == null) {
      alert('Ingrese un folio');
      return;
    }
    console.log({ fecha });

    if (fecha == null) {
      alert('Ingrese una fecha');
      return;
    }
    console.log({ total });
    if (total == null) {
      alert('Ingrese un total');
      return;
    }

    if (rut == null) {
      alert('Ingrese un rut');
      return;
    }

    setLoading(true);

    // :rut/:dteType/:folio
    fetch(`https://static.ticketapp.cl/sii/dte/${rut}/${doc}/${folio}?date=${fecha}&total=${total}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        if (!data.data) {
          alert('Documento no encontrado');
          return;
        }
        else {
          setData(data.data);
          // alert(`
          //   Documento encontrado - ${folio}
          //   Fecha: ${data.data.createdAt.split('T')[0]}
          //   Empresa: ${data.data.business}

          // `);

        }
        console.log({ data })

      })
      .catch(err => {
        setLoading(false);
        console.log({ err })
      })


  }

  if (data != null) {
    return (
      <div className="mt-2 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className='col-12 mb-4'>
              <h2 className="text-center">Documento encontrado</h2>
            </div>
            <div className="col-6 text-center">
              <h4>{data.business}</h4>
              <small>{data.rut}</small>
            </div>

            <div className="w-100"></div>

            <div className="col-4 bg-white p-3 my-3  text-center">
              <img alt='TED' className="img-fluid" src={`https://static.ticketapp.cl/ted?bcid=pdf417&text=${encodeURIComponent(data.TED)}`} />
              <small class="text-danger px-2"> Timbre electronico SII RES. {data.siiResCode} del {data.siiResDate.split('T')[0]} - Verifique en www.sii.cl</small>
            </div>

            <div className="w-100"></div>

            <div className="col-6">
              <button className="btn btn-success btn-block w-100" type="button" onClick={() => document.location.reload()}>Volver</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="py-4 mx-auto col-md-6 col-12">
        <div className="container">
          <div className="row justify-content-center">
            <div className='col-12 mb-4'>
              <h2 className='text-center'>Verificar documento</h2>
            </div>
            <div className="col">
              <small>TIPO DE DOCUMENTO</small>
              <select className="form-control" onChange={(e) => setDoc(e.target.value)}>
                <option value="0" selected disabled>Seleccione</option>
                <option value="33">Factura electronica 33</option>
                <option value="39">Boleta  electronica 39</option>
                <option value="61">Nota de credito 61</option>
                <option value="56">Nota de debito 56</option>
              </select>
              <small className="text-danger">* Solo se pueden verificar documentos emitidos por TicketApp</small>
            </div>

            <div className="w-100 py-1"></div>


            <div className="col-6">
              <small>RUT EMISOR</small>
              <input name="rut" type="text" className="form-control" onChange={(e) => setRut(e.target.value)} />
              <small className="text-muted">RUT sin puntos EJ: 76321654-2</small>
            </div>


            <div className="col-6">
              <small>FOLIO</small>
              <input name="folio" type="number" className="form-control" onChange={(e) => setFolio(parseInt(e.target.value))} />
              {folio == null && <small className="text-danger">Ingrese un numero de folio</small>}
            </div>

            <div className="w-100 py-1"></div>

            <div className="col-6">
              <small>FECHA DEL DOCUMENTO</small>
              <input name="fecha" type="date" className="form-control" onChange={(e) => setFecha(e.target.value)} />
              {fecha == null && <small className="text-danger">Ingrese una fecha</small>}
            </div>



            <div className="col-6">
              <small>MONTO TOTAL</small>
              <input className="form-control" onChange={(e) => setTotal(e.target.value)} />
            </div>

            <div className='w-100 py-3'></div>

            <div className='col-md'>
              <ReCAPTCHA
                sitekey="6Lf_PJwkAAAAAL1WCzQ9K-wvvtQEAgdXIG1K9m2s"
                onChange={onChange}
              />
            </div>

            <div className='w-100 py-3'></div>

            <div className="col">
              <button disabled={loading}  className="btn btn-success btn-block w-100" type="button" onClick={send}>Enviar</button>
            </div>
          </div>

          <div className='w-100 py-1'></div>

          <div className='col-12 text-center text-uppercase'>
            <small class="text-muted">Documentos por ticketapp
              <a href="https://ticketapp.cl" className="mx-2" target="_blank" rel="noreferrer">www.ticketapp.cl</a>
            </small>
          </div>

        </div>
      </div>
    </div>
  );
}


export default App;
